import React from "react";
import { softwareDevelopmentProcessesServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const SoftwareDevelopmentProcessesServiceDetail = () => {
  const sdpData = softwareDevelopmentProcessesServiceData;
  return (
    <>
      <PageTitle title={"Profesyonel Yazılım Geliştirme Süreçleri"} />
      <ServiceDetailsInner data={sdpData} />
    </>
  );
};

export default SoftwareDevelopmentProcessesServiceDetail;
