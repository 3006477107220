import React from "react";
import { softwareTrendsServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const SoftwareTrendsServiceDetailPage = () => {
  const stData = softwareTrendsServiceData;
  return (
    <>
      <PageTitle title={"Yazılım Trendleri ve Geleceği"} />
      <ServiceDetailsInner data={stData} />
    </>
  );
};

export default SoftwareTrendsServiceDetailPage;
