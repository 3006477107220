import React from "react";
import { webSoftwareServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const WebSoftwareServiceDetailPage = () => {
  const webData = webSoftwareServiceData;
  return (
    <>
      <PageTitle title={"Profesyonel Web Yazılım Hizmetleri"} />
      <ServiceDetailsInner data={webData} />
    </>
  );
};

export default WebSoftwareServiceDetailPage;
