import React from 'react'
import PageTitle from '../components/PageTitle'

const NotFound = () => {
    return (
        <>
            <PageTitle title={"Aradığınız sayfa bulunamadı"} />
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Sayfa bulunamadı.</p>
                    <p className="lead">
                        Bu sayfa mevcut değil.
                    </p>
                    <a href="/" className="btn btn-base">Anasayfaya git</a>
                </div>
            </div>
        </>
    )
}

export default NotFound