export const servicesList = [
    {
        name: "Masaüstü Yazılımları",
        url: "/masaustu-yazilimlari"
    },
    {
        name: "Web Yazılımları",
        url: "/web-yazilimlari"
    },
    {
        name: "Mobil Yazılımlar",
        url: "/mobil-yazilim"
    },
    {
        name: "Global Pazar Uygulamaları",
        url: "/global-pazar-uygulamalari"
    },
    {
        name: "Robotik Yazılımlar",
        url: "/robotik-yazilimlar"
    },
    {
        name: "Yazılım Geliştirme Süreçleri",
        url: "/yazilim-gelistirme-surecleri"
    },
    {
        name: "Güvenlik ve Veri Koruma",
        url: "/guvenlik-ve-veri-koruma"
    },
    {
        name: "Yazılım Trendleri ve Geleceği",
        url: "/yazilim-trendleri-ve-gelecegi"
    }
]

export const webSoftwareServiceData = {
    cover_image_url: "/assets/img/service/web-yazilimi-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Web yazılımları - Wia Yazılım",
    title: "Web Yazılımları: Dijital Varlığınızı Güçlendirin",
    top_body: [
        'Wia Yazılım olarak, işletmenizin dijital varlığını güçlendirmek ve çevrimiçi etkileşimi maksimize etmek için kapsamlı web yazılımları hizmetleri sunuyoruz. Web siteniz, markanızın dijital yüzüdür ve biz bu yüzü, işletmenizin özel ihtiyaçlarına uyacak şekilde özelleştirilmiş çözümlerle tasarlıyoruz.',
        'Ekibimiz, güncel web teknolojilerini kullanarak özgün ve kullanıcı dostu web yazılımları geliştiriyor. SEO odaklı tasarımlarımız, arama motorları tarafından daha iyi indekslenmenizi sağlayarak potansiyel müşterilere daha kolay ulaşmanıza yardımcı oluyor. Web sitenizin performansını artırmak, mobil uyumluluk sağlamak ve kullanıcı deneyimini optimize etmek için sürekli olarak gelişen teknolojileri takip ediyoruz.',
    ],
    center_image_url: "/assets/img/service/web-yazilimi.png",
    center_image_alt: "Web yazılımları - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Özelleştirilmiş Web Uygulamaları Geliştirme',
        'SEO Dostu Web Tasarım',
        'Mobil Uyumlu Çözümler',
        'Güvenlik Odaklı Yaklaşım',
        'Teknoloji Trendlerine Uyum',
        'Performans Optimizasyonu',
    ],
    middle_title: "Dijital Dönüşümünüzü Şimdi Başlatın!",
    middle_body: [
        "Web Yazılımları alanında sunduğumuz çözümlerle, işletmenizin dijital varlığını güçlendirmeye hazır mısınız? Wia Yazılım olarak, özelleştirilmiş web uygulamalarından SEO dostu tasarıma, mobil uyumlu çözümlerden güvenlik odaklı yaklaşıma kadar geniş bir yelpazede hizmet sunuyoruz. Müşterilerimizin ihtiyaçlarına uygun çözümler üreterek, online varlığınızı güçlendiriyor ve rekabet avantajı sağlıyoruz.",
        "Web yazılımları konusundaki uzman ekibimiz, iş süreçlerinizi optimize etmek ve dijital varlığınızı en üst düzeye çıkarmak için burada. Teknoloji trendlerini yakından takip ediyor, güncel çözümlerle işletmenizin dijital geleceğini şekillendiriyoruz.",
    ],
    middle_image_url: "/assets/img/service/web-yazilimi-bottom.jpg",
    middle_image_alt: 'Web yazılımları - Wia Yazılım',
    body_bottom: [
        'Müşteri verilerinin güvenliği bizim önceliğimizdir. Geliştirdiğimiz web yazılımları, en son güvenlik standartlarına uygun olarak tasarlanır ve düzenli olarak güvenlik kontrollerine tabi tutulur. Ayrıca, web sitenizin hızlı ve güvenilir olması için performans optimizasyonu çalışmalarını sürekli olarak yürütüyoruz.',
        'Her işletme farklıdır ve biz de bu farklılıkları göz önünde bulundurarak özel çözümler üretiyoruz. İş süreçlerinizi daha verimli hale getirmek ve müşterilerinizle daha güçlü bağlar kurmak için size özel web yazılımları geliştiriyoruz.'
    ],
    useful_links: [
        { name: "Wikipedia", url: "https://tr.wikipedia.org/wiki/Web_yaz%C4%B1l%C4%B1m%C4%B1" },
        { name: "Kumsal Ajans", url: "https://www.kumsalajans.com/blog/web-ve-mobil-yazilim/web-yazilimi-nedir" }
    ]
}

export const desktopSoftwareServiceData = {
    cover_image_url: "/assets/img/service/masaustu-yazilimi-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Masaüstü yazılımları - Wia Yazılım",
    title: "Masaüstü Yazılımları: İş Süreçlerinizi Daha Etkin Yönetin!",
    top_body: [
        'İşletmenizin gereksinimlerine uygun, özel çözümlerle masaüstü yazılımları konusunda Wia Yazılım olarak size liderlik ediyoruz. İş süreçlerinizi daha etkin yönetmek, verimliliği artırmak ve müşteri memnuniyetini yükseltmek için özelleştirilmiş masaüstü yazılımları geliştiriyoruz.',
        'Masaüstü yazılımlarımız, işletmenizin özel ihtiyaçlarına uygun olarak tasarlanır. İhtiyaca özel çözümlerle iş süreçlerinizi optimize eder ve verimliliği artırmanıza yardımcı oluruz.',
        'Geliştirdiğimiz masaüstü yazılımları, güvenliği ön planda tutar. Veri güvenliği konusundaki en son standartlara uygun olarak tasarlanan yazılımlarımız, işletmenizin verilerini güvende tutar.',
    ],
    center_image_url: "/assets/img/service/masaustu-yazilimi.png",
    center_image_alt: "Masaüstü yazılımları - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Özelleştirilmiş Masaüstü Uygulamaları',
        'Güvenli ve Güçlü Altyapı',
        'Kullanıcı Dostu Arayüzler',
        'Güvenlik Odaklı Yaklaşım',
        'Teknoloji Trendlerine Uyum',
        'Performans Optimizasyonu',
    ],
    middle_title: "Verimliliği Artırmanın Pratik Yolları",
    middle_body: [
        "Masaüstü yazılımlarıyla iş süreçlerinizi daha etkin bir şekilde yönetmenin ve verimliliği artırmanın pratik yollarını öğrenin. Özelleştirilmiş uygulamalar, otomasyon ve kullanıcı dostu arayüzler ile işlerinizi daha hızlı ve kolay bir şekilde gerçekleştirin.",
        "Güvenlik, masaüstü yazılımlarında kritik bir konudur. Veri koruma stratejilerini öğrenin ve işletmenizin hassas bilgilerini güvende tutmak için alabileceğiniz önlemleri keşfedin. Masaüstü yazılımlarıyla güvenliği bir araya getirme stratejilerini inceleyin.",
    ],
    middle_image_url: "/assets/img/service/masaustu-yazilimi-bottom.jpg",
    middle_image_alt: 'Masaüstü yazılımları - Wia Yazılım',
    body_bottom: [
        'Veri güvenliği, performans optimizasyonu ve özel çözümlerle iş süreçlerinizi güçlendirmek istiyorsanız, masaüstü yazılımları tam da ihtiyacınız olan çözüm olabilir. ',
    ],
    useful_links: [
        { name: "", url: "" }
    ]
}

export const mobileSoftwareServiceData = {
    cover_image_url: "/assets/img/service/mobil-yazilim-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Mobil yazılımlar - Wia Yazılım",
    title: "Mobil Yazılım: Dijital Çağda Öne Çıkmak",
    top_body: [
        'Mobil cihazların yaygın kullanımıyla birlikte, işletmelerin dijital varlıklarını mobil platformlarda güçlendirmesi kritik hale geldi. Mobil yazılımların işletmeler için sağladığı avantajları ve dijital dönüşümde nasıl öne çıkabileceğinizi keşfedin.',
        'Mobil uygulamalar, kullanıcı deneyimini baştan sona değiştiriyor. Müşterilerinizle daha yakın bir etkileşim kurmanızı sağlayacak, kullanıcı dostu ve etkileyici mobil uygulamaların işletmeniz için nasıl bir değer sağladığını öğrenin.',
    ],
    center_image_url: "/assets/img/service/mobil-yazilim.png",
    center_image_alt: "Mobil yazılımları - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Özel Mobil Uygulama Geliştirme',
        'Native ve Cross-Platform Geliştirme',
        'Webview Uygulama',
        'Oyun Geliştirme',
    ],
    middle_title: "Mobil Yazılımlarla Geleceğinize Yol Alın",
    middle_body: [
        "Mobil yazılımlar, işletmelerin dijital dünyada ayakta kalmasını ve rekabet avantajı elde etmesini sağlayan güçlü bir araçtır. Siz de işletmenizin geleceğini şekillendirmek ve müşterilerinizle daha yakın bir bağ kurmak için mobil yazılımları keşfedin. Daha fazla bilgi almak ve özel çözümlerimizden yararlanmak için bize ulaşın.",
        "Mobil yazılımlar, işletmenizin potansiyelini artırmak, müşteri tabanını genişletmek ve dijital varlığınızı güçlendirmek için güçlü bir araçtır. Wia Yazılım olarak, mobil yazılımların sunduğu fırsatları keşfetmenize yardımcı olmak için buradayız. İşletmenizin dijital dönüşümüne liderlik etmek ve geleceği birlikte inşa etmek için bize katılın.",
    ],
    middle_image_url: "/assets/img/service/mobil-yazilim-bottom.jpg",
    middle_image_alt: 'Mobil yazılımları - Wia Yazılım',
    body_bottom: [
        'Müşteri verilerinin güvenliği bizim önceliğimizdir. Geliştirdiğimiz web yazılımları, en son güvenlik standartlarına uygun olarak tasarlanır ve düzenli olarak güvenlik kontrollerine tabi tutulur. Ayrıca, web sitenizin hızlı ve güvenilir olması için performans optimizasyonu çalışmalarını sürekli olarak yürütüyoruz.',
        'İşletmenizin özel ihtiyaçlarına uygun olarak tasarlanmış, etkileyici ve kullanıcı dostu mobil uygulamalar geliştiriyoruz. Mobil cihazlara uyumlu, hızlı ve şık mobil web siteleri tasarlayarak, online varlığınızı genişletiyoruz. Mobil pazarda nasıl öne çıkabileceğinizi belirlemenize yardımcı olmak için uzmanlarımızla birlikte mobil stratejiler oluşturuyoruz. Geliştirdiğimiz mobil uygulamaları en son güvenlik standartlarına uygun olarak tasarlıyor ve işletmenizin verilerini güvenli bir şekilde koruyoruz.'
    ],
    useful_links: [
        { name: "Wikipedia", url: "https://tr.wikipedia.org/wiki/Mobil_uygulama" },
    ]
}

export const roboticCodingServiceData = {
    cover_image_url: "/assets/img/service/robotik-yazilim-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Robotik yazılım - Wia Yazılım",
    title: "Robotik Yazılım: Geleceğin Teknolojisi",
    top_body: [
        'Robotik yazılımlar, iş süreçlerini otomatikleştirerek verimliliği ve hızı artırıyor. İşletmelerin neden robotik yazılımlara yönelmeleri gerektiğini ve bu teknolojinin sağladığı avantajları keşfedin. Geleceği bugünden şekillendirmek için robotik yazılımların gücünü kullanın.',
        'Robotik yazılımlar, iş dünyasında inovasyonun ötesine geçiyor. İşletmelerin nasıl daha akıllı, daha hızlı ve daha rekabetçi olabileceklerini anlamak için robotik yazılımları nasıl kullanabileceklerini keşfedin. Dijital dönüşümünüzü hızlandırın.',
    ],
    center_image_url: "/assets/img/service/robotik-yazilim.jpg",
    center_image_alt: "Robotik yazılım - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Otomasyon Çözümleri',
        'Robotik Süreç Otomasyonu (RPA)',
        'Özel Robotik Çözümler',
    ],
    middle_title: "Robotik Yazılımların İş Süreçlerine Katkısı",
    middle_body: [
        "Robotik yazılımlar, iş süreçlerini optimize etmenin ötesinde, hataları azaltarak ve süreçleri hızlandırarak işletmelerin rekabet avantajı elde etmelerine olanak tanır. Robotik yazılımların iş süreçlerine katkısını ve nasıl kullanıldığını anlatarak işletmelerin bu teknolojiden nasıl faydalanabileceğini keşfedin.",
        "Robotik yazılımların iş süreçlerine entegrasyonu sadece verimliliği artırmakla kalmaz, aynı zamanda güvenilirlik ve güvenlik sağlar. Robotik yazılımların sağladığı güvenilirlik ve güvenlik önlemlerini keşfedin ve iş süreçlerinizi daha sağlam bir temele oturtun.",
    ],
    middle_image_url: "/assets/img/service/robotik-yazilim-bottom.jpg",
    middle_image_alt: 'Robotik yazılımlar - Wia Yazılım',
    body_bottom: [
        'Robotik yazılımlar, işletmelerin daha hızlı, daha akıllı ve daha etkili olmalarını sağlayarak geleceğe yönlendiriyor. Siz de iş süreçlerinizi optimize etmek, maliyetleri düşürmek ve rekabet avantajı elde etmek için robotik yazılımların gücünden yararlanabilirsiniz. Daha fazla bilgi almak ve işletmenizin geleceğini şekillendirmek için bize ulaşın.',
    ],
    useful_links: [
        { name: "", url: "" },
    ]
}

export const globalApplicationServiceData = {
    cover_image_url: "/assets/img/service/global-yazilim-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Global pazar yazılımları - Wia Yazılım",
    title: "Global Pazar Yazılımları: Sınırları Aşın",
    top_body: [
        'Global pazarlara adım atmak, işletmelerin büyüme potansiyelini artırmanın kritik bir yoludur. Global pazar yazılımlarının işletmelerin genişlemesine nasıl katkı sağladığını ve uluslararası rekabetin zorluklarıyla nasıl başa çıkabileceğinizi öğrenin.',
        'Global pazar yazılımları, teknolojik çözümleri kullanarak işletmelerin dünya çapında başarı elde etmelerini sağlar. Global pazarlarda rekabet avantajı sağlamak için nasıl özel yazılım çözümlerinden yararlanabileceğinizi keşfedin.',
        'Global pazarlarda başarı elde etmek için yazılımların farklı kültürlere uyumlu olması kritik bir faktördür. Yazılımlarınızın kültürel çeşitlilik gözeterek nasıl geliştirilebileceğini ve çeşitli pazarlarda nasıl etkili olabileceğinizi keşfedin.'
    ],
    center_image_url: "/assets/img/service/global-pazar.png",
    center_image_alt: "Global Pazar Yazılımları - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Çokdilli ve Çoklu Bölge Destekli Yazılımlar',
        'Yüksek Trafikli Uygulamalar',
        'Yazılım Lokalizasyonu',
        'Güvenlik Odaklı Yaklaşım',
        'Performans Optimizasyonu',
    ],
    middle_title: "Yol Haritanızı Belirleyin",
    middle_body: [
        "Global pazarlara açılmak, işletmenizin büyüme potansiyelini artırmak ve uluslararası arenada rekabet avantajı sağlamak anlamına gelir. Global pazar yazılımlarının işletmeniz için nasıl bir yol haritası çizebileceğini öğrenin.",
    ],
    //middle_image_url: "/assets/img/service/web-yazilimi-bottom.jpg",
    //middle_image_alt: 'Global Pazar Yazılımları - Wia Yazılım',
    body_bottom: [
        'Global pazar yazılımları, işletmenizin sınırlarını aşmanın ve dünya genelinde başarı elde etmenin bir anahtarıdır. Siz de işletmenizin potansiyelini en üst düzeye çıkarmak, yeni pazarlara açılmak ve rekabet avantajı sağlamak için global pazar yazılımlarıyla tanışın. Detaylı bilgi almak ve işletmenizin global arenadaki başarısını garantilemek için bize ulaşın.',
        'Global pazar yazılımları, işletmelerin dünya genelinde başarıya ulaşmaları için güçlü bir itici güçtür. Wia Yazılım olarak, işletmenizin global başarıya ulaşmasına yardımcı olacak teknolojik çözümler sunuyoruz. Sizinle birlikte işletmenizin geleceğini inşa etmek için buradayız. Geleceğinizi birlikte şekillendirmek için bize katılın.'
    ],
    useful_links: [
        { name: "", url: "" }
    ]
}

export const softwareDevelopmentProcessesServiceData = {
    cover_image_url: "/assets/img/service/yazilim-gelistirme-sureci-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Yazılım Geliştirme Süreçleri - Wia Yazılım",
    title: "Yazılım Geliştirme Süreçleri: İnovasyonun Temel Taşı",
    top_body: [
        'Yazılım geliştirme süreçleri, modern iş dünyasında rekabet avantajı elde etmek ve müşteri beklentilerini karşılamak için vazgeçilmez bir unsurdur. Başarılı yazılım geliştirme süreçlerinin işletmeler için nasıl bir inovasyon kaynağı olduğunu ve projelerinizi nasıl daha etkili yönetebileceğinizi keşfedin.',
        'Dijital dönüşüm, yazılım geliştirme süreçlerini daha stratejik hale getiriyor. İşletmelerin dijital dönüşümünü nasıl hızlandırabilecekleri ve yazılım geliştirme süreçlerinin bu süreçteki kilit rolünü keşfedin.'
    ],
    center_image_url: "/assets/img/service/yazilim-gelistirme-sureci.png",
    center_image_alt: "Yazılım Geliştirme Süreçleri - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Özel Yazılım Geliştirme',
        'Agile Yazılım Geliştirme',
        'Yazılım Danışmanlığı',
        'Süreç İyileştirme ve Optimizasyon',
        'Teknoloji Trendlerine Uyum',
        'Performans Optimizasyonu',
    ],
    middle_title: "Hızlı ve Esnek Projeler",
    middle_body: [
        'Agile yazılım geliştirme, işletmelerin hızlı değişen taleplere uyum sağlamasını sağlayan bir metodolojidir. Agile yöntemlerin işletmeler için nasıl bir avantaj sağladığını ve projelerinizi nasıl daha etkili bir şekilde yönetebileceğinizi öğrenin.',
        'Yazılım geliştirme süreçleri, en iyi pratiklere dayanmalıdır. Başarılı yazılım geliştirme süreçlerinin temel bileşenlerini ve bu süreçlerin nasıl optimize edilebileceğini inceleyerek projelerinizi güçlendirin.',
        'Yazılım geliştirme süreçlerinde güvenlik, kritik bir unsurdur. Yazılım geliştirme süreçlerindeki güvenlik adımlarını ve işletmelerin yazılım güvenliğini nasıl artırabileceğini öğrenin.',
        'Yazılım geliştirme süreçleri, veri yönetimi stratejilerini içermelidir. İşletmelerin yazılım geliştirme süreçlerinde veri yönetimini nasıl optimize edebileceklerini ve verilerin güvenliğini nasıl sağlayabileceklerini keşfedin.',
    ],
    middle_image_url: "/assets/img/service/yazilim-gelistirme-sureci-bottom.jpg",
    middle_image_alt: 'Yazılım Geliştirme Süreçleri - Wia Yazılım',
    body_bottom: [
        'Yazılım geliştirme süreçleri, işletmelerin teknolojik başarıya ulaşmaları için kritik bir rol oynar. Siz de işletmenizin potansiyelini en üst düzeye çıkarmak, projelerinizi daha etkili bir şekilde yönetmek ve rekabet avantajı elde etmek için uygun yazılım geliştirme süreçlerini keşfedin.',
        'Yazılım geliştirme süreçleri, işletmelerin geleceğini şekillendirmelerine olanak tanır. Wia Yazılım olarak, işletmenizin hedeflerine ulaşması için en uygun yazılım geliştirme süreçlerini sunuyoruz. Sizinle birlikte geleceği inşa etmek için buradayız. Geleceğinizi yazılım gücüyle şekillendirmek için bize katılın.'
    ],
    useful_links: [
        { name: "Vikipedia", url: "https://tr.wikipedia.org/wiki/Atik_yaz%C4%B1l%C4%B1m_geli%C5%9Ftirme" },
        { name: "İstanbul Boğaziçi Enstitü", url: "https://istanbulbogazicienstitu.com/agile-nedir" },

    ]
}

export const securityAndDataProtectionServiceData = {
    cover_image_url: "/assets/img/service/guvenlik-ve-veri-koruma-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Güvenlik ve Veri Koruma Stratejileri - Wia Yazılım",
    title: "Güvenlik ve Veri Koruma Stratejileri",
    top_body: [
        'Dijital çağda, güvenlik ve veri koruma, işletmelerin varlıklarını sürdürmek ve müşteri güvenini kazanmak için kritik öneme sahiptir. Güvenlik tehditleriyle başa çıkmanın ve verileri korumanın en iyi stratejilerini keşfedin. İşletmenizi dijital dünyada güvende tutmak için adımlarınızı atın.',
        'Veri koruma, işletmelerin itibarını korumanın yanı sıra yasal zorunlulukları da yerine getirmelerine yardımcı olur. Veri koruma çözümlerinin işletmelerin verilerini nasıl güvende tutabileceğini ve güvenlik stratejilerini nasıl geliştirebileceğinizi öğrenin.'
    ],
    center_image_url: "/assets/img/service/guvenlik-ve-veri-koruma.jpg",
    center_image_alt: "Güvenlik ve Veri Koruma Stratejileri - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Ağ Güvenliği Çözümleri',
        'Veri Şifreleme ve Güvenliği',
        'Siber Güvenlik Denetimleri',
        'Eğitim ve Farkındalık Programları',
    ],
    middle_title: "İşletmenizi Dış Tehditlere Karşı Koruyun",
    middle_body: [
        'Ağ güvenliği, işletmelerin dış tehditlere karşı ilk savunma hattıdır. İşletmelerin neden güçlü bir ağ güvenliği stratejisine ihtiyaç duyduğunu ve bu stratejinin nasıl oluşturulabileceğini öğrenin.',
        'Hassas verilerinizi şifrelemek, işletmenizin itibarını korumanın yanı sıra yasal zorunlulukları da yerine getirmenize yardımcı olur. Veri şifreleme yöntemlerini ve işletmelerin bu teknolojiyi nasıl kullanabileceğini keşfedin.',
        'Siber güvenlik denetimleri, işletmelerin siber tehditlere karşı güçlü bir savunma oluşturmasına yardımcı olur. Düzenli siber güvenlik denetimlerinin önemini ve bu denetimlerin nasıl planlanabileceğini inceleyerek işletmenizi güvende tutma stratejilerini keşfedin.',
        'İç tehditlere karşı etkili bir savunma, çalışanlarınızın güvenlik konusunda bilinçli olmalarından geçer. Çalışan eğitimi programlarının işletmenizin güvenliği üzerindeki etkisini ve bu programları nasıl başarılı bir şekilde uygulayabileceğinizi öğrenin.'
    ],
    middle_image_url: "/assets/img/service/guvenlik-ve-veri-koruma_bottom.jpg",
    middle_image_alt: 'Güvenlik ve Veri Koruma Stratejileri - Wia Yazılım',
    body_bottom: [
        'Güvenlik ve veri koruma, işletmelerin dijital dünyada ayakta kalmasını sağlamanın kritik bir unsuru haline gelmiştir. Siz de işletmenizin güvenliğini en üst düzeye çıkarmak, müşteri güvenini kazanmak ve geleceğinize güvenle bakmak için uygun güvenlik ve veri koruma stratejilerini keşfedin.',
        'Güvenlik ve veri koruma, işletmelerin rekabet avantajını sürdürebilmeleri ve müşteri güvenini kazanabilmeleri için kritik bir unsur olmaya devam ediyor. Wia Yazılım olarak, işletmenizin güvenliğini sağlamak ve geleceğe güvenle ilerlemek için buradayız. Bize katılın ve güvenlikte öncü adımlar atarak işletmenizi güvende tutun.'
    ],
    useful_links: [
        { name: "", url: "" }
    ]
}

export const softwareTrendsServiceData = {
    cover_image_url: "/assets/img/service/yazilim-trendleri-gelecek-cover.jpg",
    cover_image_icon: "/assets/img/service/service-icon-1.png",
    cover_image_alt: "Yazılımın Geleceği: İnovasyon ve Trendler - Wia Yazılım",
    title: "Yazılımın Geleceği: İnovasyon ve Trendler",
    top_body: [
        'Yazılım dünyası sürekli değişiyor ve gelişiyor. En güncel yazılım trendlerini ve gelecekteki inovasyonları keşfedin. İşletmenizin rekabet avantajını sürdürebilmesi için teknolojik değişimlere nasıl adapte olabileceğinizi öğrenin.',
        'Yazılım trendleri, iş dünyasını etkileyen kritik bir faktördür. Gelecekteki yazılım trendlerinin iş dünyasına nasıl yön verebileceğini ve işletmelerin bu trendlere nasıl uyum sağlayabileceğini keşfedin.'
    ],
    center_image_url: "/assets/img/service/yazilim-trendleri-gelecek.jpg",
    center_image_alt: "Yazılımın Geleceği ve Trendler - Wia Yazılım",
    features_name: "Neler Yapıyoruz",
    features: [
        'Kurumsal Yazılım Mimarileri',
        'Yapay Zeka ve Makine Öğrenimi Çözümleri',
        'Blockchain Teknolojisi',
        'IoT (Nesnelerin İnterneti) Uygulamaları',
        'DevOps Hizmetleri',
    ],
    middle_title: "Yazılım Trendleriyle Geleceği Şekillendirin",
    middle_body: [
        'Yapay zeka, iş dünyasını dönüştürüyor ve gelecekte iş süreçlerini nasıl etkileyeceğini bilmek kritik önem taşıyor. Yapay zeka uygulamalarının iş süreçlerine entegrasyonunu ve işletmelerin nasıl fayda sağlayabileceğini keşfedin.',
        'Blockchain, iş dünyasında güvenilirlik ve şeffaflık sağlamak için önemli bir rol oynuyor. İşletmelerin neden blockchain teknolojisini kullanmaları gerektiğini ve bu teknolojinin avantajlarını öğrenin.',
        'Nesnelerin İnterneti (IoT), işletmelerin daha akıllı ve verimli olmalarını sağlıyor. IoT`nin işletme verimliliğini nasıl artırabileceğini ve iş dünyasının bu teknolojiyi nasıl kullanabileceğini keşfedin.',
        'DevOps, yazılım geliştirme süreçlerini hızlandırmak ve daha etkili hale getirmek için önemli bir metodolojidir. DevOps`un işletmeler için sağladığı avantajları ve bu prensiplere dayalı olarak nasıl bir geçiş yapabileceğinizi öğrenin.',
    ],
    middle_image_url: "/assets/img/service/yazilim-trendleri-gelecek-bottom.jpg",
    middle_image_alt: 'Yazılımın Geleceği ve Trendler - Wia Yazılım',
    body_bottom: [
        'Yazılım trendleri, iş dünyasının geleceğini şekillendirmenin önemli bir aracıdır. Siz de işletmenizin rekabet avantajını sürdürebilmek ve geleceğe hazır olmak için en yeni yazılım trendlerini takip edin. Detaylı bilgi almak ve işletmenizin geleceğini inşa etmek için bize ulaşın.',
        'Yazılım trendleri, geleceğin iş dünyasını şekillendiriyor ve lider olmak için bu trendlere uyum sağlamak kritik bir adımdır. Wia Yazılım olarak, işletmenizin geleceğini planlamak ve en yeni teknolojilere uyum sağlamak için size rehberlik etmeye hazırız. İlerideki başarılarınızı birlikte inşa etmek için bize katılın.'
    ],
    useful_links: [
        { name: "", url: "" }
    ]
}