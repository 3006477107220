import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { FloatingWhatsApp } from "react-floating-whatsapp";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>

    <FloatingWhatsApp
      accountName="WiaSoft"
      phoneNumber="908503080942"
      avatar="/assets/img/logo/logo-w.jpg"
      chatMessage="WiaSoft'a hoşgeldiniz, size nasıl yardımcı olabiliriz?"
      placeholder="Mesaj"
      statusMessage="İşletme Hesabı"
      allowClickAway
      darkMode
    />

  </React.StrictMode>
);
