import React from "react";
import { roboticCodingServiceData } from "../../data/services-data";
import PageTitle from "../../components/PageTitle";
const ServiceDetailsInner = React.lazy(() =>
  import("../../components/ServiceDetailsInner")
);

const RoboticCodingServiceDetailPage = () => {
  const roboticData = roboticCodingServiceData;
  return (
    <>
      <PageTitle title={"Profesyonel Robotik Kodlama"} />
      <ServiceDetailsInner data={roboticData} />
    </>
  );
};

export default RoboticCodingServiceDetailPage;
